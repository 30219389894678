/*
 * @Description: 定增
 */
import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/Index.vue'

const FixedRouter: Array<RouteRecordRaw> = [
  {
    path: '/fixed',
    component: Layout,
    redirect: '/fixed/index',
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "guide" */ '@/views/fixed-increase/Index.vue'),
        name: 'Fixed',
        meta: {
          title: 'fixed',
          icon: '#iconguide'
        }
      }
    ]
  }
]

export default FixedRouter
