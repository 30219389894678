/*
 * @Description: 北向资金
 * @Author: LH
 * @Date: 2023-02-08
 */
import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/Index.vue'

const northboundRouter: Array<RouteRecordRaw> = [
  {
    path: '/northboundFunds',
    component: Layout,
    redirect: '/northboundFunds/index',
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "guide" */ '@/views/northbound-funds/detailed/Index.vue'),
        name: 'northboundFunds',
        meta: {
          title: '北向资金',
          icon: '#iconguide'
        }
      },
      {
        path: 'shares',
        component: () => import(/* webpackChunkName: "guide" */ '@/views/northbound-funds/Individual-shares/Index.vue'),
        name: 'IndividualShares',
        meta: {
          title: '北向资金个股明细',
          icon: 'shares'
        }
      },
      {
        path: 'industry',
        component: () => import(/* webpackChunkName: "guide" */ '@/views/northbound-funds/industry/Index.vue'),
        name: 'industry',
        meta: {
          title: '北向资金行业明细',
          icon: 'industry'
        }
      },
      {
        path: 'share-page',
        component: () => import(/* webpackChunkName: "guide" */ '@/views/northbound-funds/share-page/PC/Index.vue'),
        name: 'share-page',
        meta: {
          title: '北向资金行业明细',
          icon: 'share'
        }
      }
    ]
  }
]

export default northboundRouter
