/*
 * @Description: 泸深300懂监高信息采集
 * @Author: LH
 * @Date: 2023-02-24
 */
import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/Index.vue'

const northboundRouter: Array<RouteRecordRaw> = [
  {
    path: '/information',
    component: Layout,
    redirect: '/information/index',
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "guide" */ '@/views/information-acquisition/index.vue'),
        name: 'information',
        meta: {
          title: '泸深300懂监高信息采集',
          icon: '#iconguide'
        }
      }
    ]
  }
]

export default northboundRouter
