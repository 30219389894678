/*
 * @Description: 颜色指令
 */
import { Directive } from 'vue'

//百分数转化为小数
function toPoint(percent:any){
  var str=percent.replace("%","");
  str= str/100;
  return str;
}

//小数转化为百分数
function toPercent(point:any){
  var str=Number(point*100).toFixed(1);
  str+="%";
  return str;
}

function getClass(el:any,val:any){
  if( val > 0 ){
    let arr = el.className.split(' ').filter((item:any) => item != 'green' && item != 'red' )
    // console.log('00000', arr)
    // // el.className = arr.join(' ')
    // console.log(el.className)
    el.className = arr.join(' ') + ' red'
  }else if( val < 0 ){
    el.className = el.className + ' green'
  }else{
    el.className = el.className + ' grey'
  }
}

export const dClass: Directive = {
  mounted(el, binding) {
    if( typeof binding.value === 'string' ){
      if( binding.value.indexOf('%') !== -1 ){
        let val = toPoint(binding.value)
        return getClass(el,val)
      }else{
        return getClass(el,binding.value)
      }
    }else{
      return getClass(el,binding.value)
    }
  },
  beforeUpdate(el, binding){
    if( typeof binding.value === 'string' ){
      if( binding.value.indexOf('%') !== -1 ){
        let val = toPoint(binding.value)
        return getClass(el,val)
      }else{
        return getClass(el,binding.value)
      }
    }else{
      return getClass(el,binding.value)
    }
  }
}
