/*
 * @Description: axios 封装网络请求
 */

import { useStore } from '@/store'
import HttpClient, { HttpClientConfig } from 'axios-mapper'
import networkConfig from '@/config/default/net.config'
let timer = true;
// interface Headers{
//   token?: String,
//   userId?: String | Number
// }
const https = (host = 'host') => {
  // const headers:Headers = {}
  // headers.token = window['$userLoginInfo']?.token || ''
  // headers.userId = window['$userLoginInfo']?.userId || ''
  // console.log(networkConfig.host) 
  const config: HttpClientConfig = {
    baseURL: networkConfig[host],
    headers: {
      token: window['$userLoginInfo']?.token || '',
      userId: window['$userLoginInfo']?.userId || '',
    }
  }
  const https =  new HttpClient(config)
  // Add a request interceptor
  https.httpClient.interceptors.request.use(function (config) {
    // Do something before request is sent
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

  // Add a response interceptor
  https.httpClient.interceptors.response.use(function (response) {
    // Do something with response data
    // console.log('fanhuizhi', response)
    const { code } = response.data
    if( code === '4001' || code === 4001 || code === 3001 || code === '3001'  ){
      if( timer ){
        timer = false;
        window['$logOut'].logOut()
      }
      setTimeout(()=>{
        timer = true
      },3000)
    }
    return response;
  }, function (error) {
    // Do something with response error
    return Promise.reject(error);
  });

  return https
}

export default https
