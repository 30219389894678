<!--
 * @Description: 主视图
 * @Author: ZY
 * @Date: 2020-12-24 10:36:58
 * @LastEditors: ZY
 * @LastEditTime: 2021-01-11 10:27:35
-->
<template>
  <section class="app-main">
    <img
      src="https://resource.jufxt.com/static/img/home-logo.png"
      class="home-logo"
      v-if="$route.path === '/home/index'"
    >
    <!-- <Search></Search> -->
    <!-- <transition
      name="fade-transform"
      mode="out-in"
    > -->
      <!-- <keep-alive :include="cachedViews"> -->
        <router-view :key="$route.fullPath" />
      <!-- </keep-alive> -->
    <!-- </transition> -->
  </section>
</template>

<script lang="ts">
import { useStore } from '@/store'
import { defineComponent, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
// import Search from '@/components/search-input/Index.vue'
// import { Avatar } from '@element-plus/icons-vue'

export default defineComponent({
  components: {
    // Search
  },
  setup() {
    const router = useRouter()
    const store = useStore()
    const route = useRoute()
    const input2 = ref('')
    const cachedViews = () => {
      return store.state.tagViews.cachedViews
    }
    const key = () => {
      return route.fullPath
    }

    watch(() =>router.currentRoute.value,(newValue,oldValue)=> {
      // console.log(document.querySelector('.app-main'))
      // console.log('路有变化', router.currentRoute.value.fullPath)
      const html = document.querySelector('.app-main');
      if( html ){
        // document.querySelector('.app-main')!.scrollIntoView(true)
      }
    },{ immediate: true })
    // console.log(Avatar)
    return {
      cachedViews,
      key,
      input2
      // Avatar
    }
  }
})
</script>

<style lang="scss" scoped>
.app-main {
  /* 50= navbar  50  */
  min-height: calc(100vh - 50px);
  width: 100%;
  position: relative;
  overflow: hidden;
  background: #ECEFF8;
  .home-logo{
    max-width: 100%;
    padding: 20px 35px 0;
    vertical-align: middle;
  }

}

.fixed-header+.app-main {
  padding-top: 50px;
  height: 100vh;
  overflow: auto;
}

.hasTagsView {
  .app-main {
    /* 84 = navbar + tags-view = 50 + 34 */
    min-height: calc(100vh - 84px);
  }

  .fixed-header+.app-main {
    padding-top: 84px;
  }
}
</style>
