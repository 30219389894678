/*
 * @Description: 融资融券
 * @Author: LH
 * @Date: 2023-02-24
 */
import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/Index.vue'

const northboundRouter: Array<RouteRecordRaw> = [
  {
    path: '/marginTrading',
    component: Layout,
    redirect: '/marginTrading/index',
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "guide" */ '@/views/margin-trading/home-page/index.vue'),
        name: 'marginTrading',
        meta: {
          title: '融资融券',
          icon: '#iconguide'
        }
      },
      {
        path: 'shares',
        component: () => import(/* webpackChunkName: "guide" */ '@/views/margin-trading/individual-shares/index.vue'),
        name: 'marginTradingShares',
        meta: {
          title: '融资融券个股明细',
          icon: 'shares'
        }
      },
      {
        path: 'industry',
        component: () => import(/* webpackChunkName: "guide" */ '@/views/margin-trading/trade/index.vue'),
        name: 'marginTradingIndustry',
        meta: {
          title: '融资融券行业明细',
          icon: 'industry'
        }
      },
      {
        path: 'share-page',
        component: () => import(/* webpackChunkName: "guide" */ '@/views/margin-trading/share-page/index.vue'),
        name: 'marginTradingSharePage',
        meta: {
          title: '融资融券分享页',
          icon: 'share'
        }
      }
    ]
  }
]

export default northboundRouter
