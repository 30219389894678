/*
 * @Description:
 * @Autor: ZY
 * @Date: 2020-12-07 10:30:20
 * @LastEditors: ZY
 * @LastEditTime: 2021-01-27 19:20:07
 */
import './public-path'
import { createApp, Directive } from 'vue'
import App from './App.vue'
// import './pwa/registerServiceWorker'
import router from './router'
import { store } from './store'
import { loadAllPlugins } from './plugins'
import '@/assets/iconfont/iconfont.css'
import '@/styles/index.scss'
import 'normalize.css'
import * as directives from '@/directives'
import '@/permission'

let app:any = null;

function render(props: any) {
  // console.log(999,props)
  if( props ){
    window['$props'] = props.router
    window['$logOut'] = props.api
    window['$userLoginInfo'] = props.userInfo
  }
 
  
  
  app = createApp(App)
  // 加载所有插件
  loadAllPlugins(app)

  // 自定义指令
  Object.keys(directives).forEach(key => {
    app.directive(key, (directives as { [key: string ]: Directive })[key])
  })

  app.use(store).use(router).mount('#app-v3')
}

if (!window['__POWERED_BY_QIANKUN__']) {
  // 全局变量来判断环境
  render(null)
}

/**
 * bootstrap 只会在微应用初始化的时候调用一次，下次微应用重新进入时会直接调用 mount 钩子，不会再重复触发 bootstrap。
 * 通常我们可以在这里做一些全局变量的初始化，比如不会在 unmount 阶段被销毁的应用级别的缓存等。
 */
export async function bootstrap() {
  console.log('现在进入子应用c-child的bootstraped阶段')
}

/**
 * 应用每次进入都会调用 mount 方法，通常我们在这里触发应用的渲染方法
 */
export async function mount(props: any) {
  // console.log('现在进入子应用c-child的mount周期', props)
  props.onGlobalStateChange((state:any) => {
    if( state.userInfo ){
      props.userInfo = state.userInfo
    }
  }, true);
  render(props)
}

/**
 * 应用每次 切出/卸载 会调用的方法，通常在这里我们会卸载微应用的应用实例
 */
export async function unmount() {
  app.unmount();
  app._container.innerHTML = '';
  app = null;
  // router = null;
  // history.destroy();
}

/**
 * 可选生命周期钩子，仅使用 loadMicroApp 方式加载微应用时生效
 */
export async function update(props: any) {
  console.log('update props', props)
}



