/*
 * @Description: 财报
 */
import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/Index.vue'

const FinancialRouter: Array<RouteRecordRaw> = [
  {
    path: '/financial',
    component: Layout,
    redirect: '/financial/index',
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "guide" */ '@/views/financial-report/index/Index.vue'),
        name: 'Financial',
        meta: {
          title: 'financial',
          icon: '#iconguide'
        }
      },
      {
        path: 'detailed',
        component: () => import(/* webpackChunkName: "guide" */ '@/views/financial-report/detailed/index.vue'),
        name: 'FinancialDetailed',
        meta: {
          title: 'FinancialDetailed',
          icon: '#iconguide'
        }
      }
    ]
  }
]

export default FinancialRouter
