/*
 * @Description: 引导页
 * @Author: ZY
 * @Date: 2021-01-21 20:13:03
 * @LastEditors: ZY
 * @LastEditTime: 2021-01-21 21:24:27
 */
import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/Index.vue'

const DiagnosisRouter: Array<RouteRecordRaw> = [
  {
    path: '/diagnosis',
    component: Layout,
    redirect: '/diagnosis/index',
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "guide" */ '@/views/diagnosis/Index.vue'),
        name: 'Diagnosis',
        meta: {
          title: 'diagnosis',
          icon: '#iconguide'
        }
      }
    ],
  }
]

export default DiagnosisRouter
