
import { useStore } from '@/store'
import { defineComponent, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
// import Search from '@/components/search-input/Index.vue'
// import { Avatar } from '@element-plus/icons-vue'

export default defineComponent({
  components: {
    // Search
  },
  setup() {
    const router = useRouter()
    const store = useStore()
    const route = useRoute()
    const input2 = ref('')
    const cachedViews = () => {
      return store.state.tagViews.cachedViews
    }
    const key = () => {
      return route.fullPath
    }

    watch(() =>router.currentRoute.value,(newValue,oldValue)=> {
      // console.log(document.querySelector('.app-main'))
      // console.log('路有变化', router.currentRoute.value.fullPath)
      const html = document.querySelector('.app-main');
      if( html ){
        // document.querySelector('.app-main')!.scrollIntoView(true)
      }
    },{ immediate: true })
    // console.log(Avatar)
    return {
      cachedViews,
      key,
      input2
      // Avatar
    }
  }
})
