/*
 * @Description: 引导页
 * @Author: ZY
 * @Date: 2021-01-21 20:13:03
 * @LastEditors: ZY
 * @LastEditTime: 2021-01-21 21:24:27
 */
import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/Index.vue'

const AgencyRouter: Array<RouteRecordRaw> = [
  {
    path: '/agency',
    component: Layout,
    redirect: '/agency/index',
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "guide" */ '@/views/agency/Index.vue'),
        name: 'Agency',
        meta: {
          title: 'agency',
          icon: '#iconguide'
        }
      },
      {
        path: 'detail',
        component: () => import(/* webpackChunkName: "guide" */ '@/views/agency/Detail.vue'),
        name: 'AgencyDetail',
        meta: {
          title: 'agency-detail',
          icon: '#iconguide'
        }
      },
      {
        path: 'list',
        component: () => import(/* webpackChunkName: "guide" */ '@/views/agency/List.vue'),
        name: 'AgencyList',
        meta: {
          title: 'agency-list',
          icon: '#iconguide'
        }
      },
      {
        path: 'report',
        component: () => import(/* webpackChunkName: "guide" */ '@/views/agency/Report.vue'),
        name: 'AgencyReport',
        meta: {
          title: 'agency-report',
          icon: '#iconguide'
        }
      },
      {
        path: 'reportgg',
        component: () => import(/* webpackChunkName: "guide" */ '@/views/agency/ReportGg.vue'),
        name: 'AgencyGgReport',
        meta: {
          title: 'agency-report',
          icon: '#iconguide'
        }
      },
      {
        path: 'buy-rating',
        component: () => import(/* webpackChunkName: "guide" */ '@/views/agency/BuyRatingList.vue'),
        name: 'BuyRating',
        meta: {
          title: 'buy-rating',
          icon: '#iconguide'
        }
      }
    ]
  }
]

export default AgencyRouter
