/*
 * @Description: 引导页
 * @Author: ZY
 * @Date: 2021-01-21 20:13:03
 * @LastEditors: ZY
 * @LastEditTime: 2021-01-21 21:24:27
 */
import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/Index.vue'

const ResearchRouter: Array<RouteRecordRaw> = [
  {
    path: '/research',
    component: Layout,
    redirect: '/research/index',
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "guide" */ '@/views/research/Index.vue'),
        name: 'Research',
        meta: {
          title: 'research',
          icon: '#iconguide'
        }
      },
      {
        path: 'detail',
        component: () => import(/* webpackChunkName: "guide" */ '@/views/research/Detail.vue'),
        name: 'ResearchDetail',
        meta: {
          title: 'research',
          icon: '#iconguide'
        }
      },
      {
        path: 'list',
        component: () => import(/* webpackChunkName: "guide" */ '@/views/research/List.vue'),
        name: 'ResearchList',
        meta: {
          title: 'research-list',
          icon: '#iconguide'
        }
      },
      {
        path: 'frequency-list',
        component: () => import(/* webpackChunkName: "guide" */ '@/views/research/FrequencyList.vue'),
        name: 'FrequencyList',
        meta: {
          title: 'FrequencyList',
          icon: '#iconguide'
        }
      },
      {
        path: 'mechanism-list',
        component: () => import(/* webpackChunkName: "guide" */ '@/views/research/MechanismList.vue'),
        name: 'MechanismList',
        meta: {
          title: 'MechanismList',
          icon: '#iconguide'
        }
      }
    ]
  }
]

export default ResearchRouter
