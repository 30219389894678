/*
 * @Description: 引导页
 * @Author: ZY
 * @Date: 2021-01-21 20:13:03
 * @LastEditors: ZY
 * @LastEditTime: 2021-01-21 21:24:27
 */
import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/Index.vue'

const AnalysisRouter: Array<RouteRecordRaw> = [
  {
    path: '/analysis',
    component: Layout,
    redirect: '/analysis/index',
    children: [
      {
        path: 'index',
        component: () => import(/* webpackChunkName: "guide" */ '@/views/analysis/Index.vue'),
        name: 'Analysis',
        meta: {
          title: 'analysis',
          icon: '#iconguide'
        }
      },
      {
        path: 'detail',
        component: () => import(/* webpackChunkName: "guide" */ '@/views/analysis/Detail.vue'),
        name: 'AnalysisDetail',
        meta: {
          title: 'detail',
          icon: '#iconguide'
        }
      }
    ]
  }
]

export default AnalysisRouter
