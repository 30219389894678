//给当前app的全局属性上挂载一个过滤器对象
const checkNumber = (data:any) => {
  return typeof data == "number" ? data : Number(data);
}
const numberFixed = (data:any, count:any) => {
  return checkNumber(data).toFixed(count);
}
const prefixSuffix = (data:any, prefix:any, suffix:any) => {
  if (data == undefined || data == null) {
    return null;
  }
  if (prefix !== undefined) {
    data = prefix + data;
  }
  if (suffix !== undefined) {
    data = data + suffix;
  }
  return data;
};
export default {
  numFilter: (value:any) => {
    if( value > 0 ){
      return '+' + value + '%'
    }
    return value + '%'
  },
  codeFilter: (value:any,position:any) => {
    if( !value ){
      return value;
    }
    return value.split('.')[position]
  },
  checkNumber: checkNumber,
  numberFixed: numberFixed,
  mktcap: (data:any, n:any, m:any, suffix:any) => {
    if (data == undefined) {
      return 0;
    }
    data = numberFixed(data / n, m);
    return prefixSuffix(data, undefined, suffix);
  }
  
}